import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

function StyleSheet() {
	const { json } = useSelector((state) => state?.main);
	const variable = useMemo(
		() => ({
			"--color-primary": json?.color_font_primary,
			"--color-secondary": json?.color_font_secondary,
			"--img-bg-main": `url(${json?.background_main_image})`,
			"--img-bg-long": `url(${json?.background_long_image})`
		}),
		[json]
	);
	useEffect(() => {
		const doc = document.documentElement;
		Object.keys(variable).forEach((key) => {
			doc.style.setProperty(key, `${variable[key]}`);
		});
	}, [variable]);
	return <></>;
}

export default StyleSheet;
