import React from "react";
import { useSelector } from "react-redux";

const Footer = () => {
	const { json } = useSelector((state) => state?.main);

	return (
		<footer>
			<div className="left">
				<img
					src="https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/ffim2021fall/march/ff_logo.png"
					alt=""
				/>
			</div>
			<div className="center">
				<p>Copyright Ⓒ Garena International.</p>
				<p>Trademarks belong to their respective owners. All Rights Reserved.</p>
			</div>
			<div className="right">
				<a href={json?.facebook_url} rel="noreferrer" target="_blank">
					<img
						src="https://cdngarenanow-a.akamaihd.net/webid/FF/ffim2021spring/ico_fb.png"
						alt=""
					/>
				</a>
				<a href={json?.instagram_url} rel="noreferrer" target="_blank">
					<img
						src="https://cdngarenanow-a.akamaihd.net/webid/FF/ffim2021spring/ico_ig.png"
						alt=""
					/>
				</a>
				<a href={json?.youtube_url} rel="noreferrer" target="_blank">
					<img
						src="https://cdngarenanow-a.akamaihd.net/webid/FF/ffim2021spring/ico_yt.png"
						alt=""
					/>
				</a>
				<a href={json?.discord_url} rel="noreferrer" target="_blank">
					<img
						src="https://cdngarenanow-a.akamaihd.net/webid/FF/ffim2021spring/ico_discord.png"
						alt=""
					/>
				</a>
			</div>
		</footer>
	);
};

export default Footer;
