import React, { lazy, Suspense } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	useNavigate,
	Outlet,
} from "react-router-dom";

// Component
import ErrorBoundary from "ErrorBoundary";
import ViewportRestrict from "helper/component/viewport";
import Loader from "helper/component/loader";
import NotFound from "helper/component/404";
import LoadingScreen from "helper/component/loadingscreen";
import { componentLoader } from "helper";
import Header from "components/Header";
import Footer from "components/Footer";
import ModalCont from "components/Modal";
import StyleSheet from "components/StyleSheet";

// REDUX
import { get_json, check_error, check_login } from "redux/actions/main";
import { set_token } from "redux/actions/auth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Pages
const Registration = lazy(() =>
	componentLoader(() => import("pages/Registration"))
);
const Login = lazy(() => componentLoader(() => import("pages/Login")));
const Success = lazy(() => componentLoader(() => import("pages/Success")));
const Closed = lazy(() => componentLoader(() => import("pages/Closed")));

export const Main = (props) => {
	const dispatch = useDispatch();
	const { loader, json } = useSelector((state) => state?.main);

	useEffect(() => {
		dispatch(get_json(window.localStorage.getItem("series")));
		dispatch(check_error());
	}, [dispatch]);

	return (
		<Router basename={process.env.REACT_APP_BASENAME}>
			<div
				id="Main"
				className="parent-container container-fluid p-0"
				style={{
					backgroundImage: `url(${json?.background_main_image}), url(${json?.background_long_image})`,
				}}
			>
				<ErrorBoundary>
					<Suspense fallback={<LoadingScreen />}>
						<ViewportRestrict display={false} type="landscape" />
						<Loader loader={loader} />
						<StyleSheet />
						<Header />
						<div className="content-container">
							<Routes>
								<Route path="/auth" element={<Auth />} />
								<Route path="/" element={<Layout />}>
									<Route path="/:series/:city" element={<Login />} />
									<Route
										path="/:series/:city/registration"
										element={<Registration />}
									/>
									<Route path="/:series/:city/success" element={<Success />} />
									<Route path="/:series/:city/closed" element={<Closed />} />
								</Route>
								<Route path="*" element={<NotFound />} />
							</Routes>
						</div>
						<Footer />
						<ModalCont />
					</Suspense>
				</ErrorBoundary>
			</div>
		</Router>
	);
};

const Auth = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(set_token());
	}, [dispatch]);

	return <div />;
};

const Layout = () => {
	const { series, city } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	useEffect(() => {
		window.localStorage.setItem("series", series);
		window.localStorage.setItem("city", city);
		window.localStorage.setItem(
			"target_url",
			window.location.origin + "/" + series + "/" + city
		);

		if (!series || !city) {
			navigate("/404");
		}
	}, [series, city, navigate, dispatch]);

	useEffect(() => {
		dispatch(check_login(city));
	}, [dispatch, city]);

	return (
		<>
			<Outlet />
		</>
	);
};

export default Main;
