import axios from "axios";
import * as main from "./main";

/**
 * Get all param url
 */
export const getAllUrlParam = (url = window.location) => {
	let params = {};

	new URL(url).searchParams.forEach(function (val, key) {
		if (params[key] !== undefined) {
			if (!Array.isArray(params[key])) {
				params[key] = [params[key]];
			}
			params[key].push(val);
		} else {
			params[key] = val;
		}
	});

	return params;
};

const ls = window.localStorage;

export const set_param = (data) => ({
	type: "SET_PARAM",
	data,
});

export const set_callback = (data) => ({
	type: "SET_CALLBACK",
	data,
});

export const set_url_code = (data) => ({
	type: "SET_URL_CODE",
	data,
});

export const set_token = () => {
	return (dispatch, getState) => {
		const { auth } = getState();
		const params = getAllUrlParam();
		let param = Object.keys(params).length === 0 ? auth?.param : params;
		if (param?.access_token || param?.gop_access_token) {
			dispatch(get_jwt(param));
		} else {
			redirect_login();
		}
	};
};

export const get_jwt = (param) => {
	return (dispatch, getState) => {
		dispatch(main.toggle_loader(true));
		axios
			.get(
				`oauth/callback/?access_token=${param.access_token}&account_id=${param.account_id}&lang=${param.lang}&region=${param.region}`
			)
			.then((resp) => {
				ls.setItem("token", resp.data.token);
				axios.defaults.headers[process.env.REACT_APP_TOKEN_HEADER_NAME] =
					resp.data.token;
				dispatch(set_param(param));
				redirect_login();
			})
			.catch((err) => {
				// Send error to analytics
				// analyticsAPICallErrorReporting(err);
			});
	};
};

const redirect_login = () => {
	if (window.localStorage.getItem("target_url")) {
		window.location.href = window.localStorage.getItem("target_url");
		// setTimeout(() => {
		// 	window.localStorage.removeItem("target_url");
		// }, 100);
	}
};
