import React from "react";
import { useSelector } from "react-redux";

const ErrorGeneral = ({ data }) => {
	const { json } = useSelector((state) => state?.main);
	const err_map = {
		0: "Data Pemain Tidak Ditemukan",
		2: "Open ID Tidak Ditemukan",
		3: "Data Pemain Tidak Ditemukan",
	};

	return (
		<>
			<div className="modalHeader">
				<h1>{json?.txt_error}</h1>
			</div>
			<div className="modalBody">
				<p>{json?.[data] || err_map?.[data] || data}</p>
			</div>
		</>
	);
};

export default ErrorGeneral;
