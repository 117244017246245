import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { put_data } from "redux/actions/main";

const Error = ({ data }) => {
	const { json } = useSelector((state) => state?.main);
	const dispatch = useDispatch();

	return (
		<>
			<div className="modalHeader">
				<h1>REGISTRASI</h1>
				<h1>GAGAL</h1>
			</div>
			<div className="modalBody">
				<p>{json?.[data] || data}</p>
				<img
					src="https://cdngarenanow-a.akamaihd.net/webid/FF/ffim2021spring/FF_Comic_chickenboy_Diefast.png"
					alt=""
				/>
			</div>
			<div className="modalFooter">
				<div className="button" onClick={() => dispatch(put_data("popup_open", false))}>
					<img src="https://cdngarenanow-a.akamaihd.net/webid/FF/ffim2021spring/daftar kembali.png" alt="" />
				</div>
			</div>
		</>
	);
};

export default Error;
