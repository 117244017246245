import axios from "axios";
import { err_msg, getUrlParam } from "helper";
// import { set_hash } from './auth';
// import { put_hash } from './hash';

const cdn = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
	withCredentials: false,
	transformRequest: [
		(data, headers) => {
			delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME];
			return data;
		},
	],
});

const common = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/webid/TranslateCommon/`,
	withCredentials: false,
});

export const toggle_popup = (bool, tipe) => ({
	type: "TOGGLE_POPUP",
	bool,
	tipe,
});

export const toggle_loader = (data) => ({
	type: "TOGGLE_LOADER",
	data: data,
});

export const toggle_loader_hash = (data) => ({
	type: "TOGGLE_LOADER_HASH",
	data: data,
});

export const put_data = (key, data) => ({
	type: "PUT_DATA",
	key,
	data,
});

export const check_error = () => {
	return (dispatch, getState) => {
		const err = getUrlParam("err");

		if (err) {
			dispatch(put_data("popup_open", true));
			dispatch(put_data("popup_type", "errorGeneral"));
			dispatch(put_data("popup_data", err));
		}
	};
};

export const check_login = (cityName) => {
	return (dispatch, getState) => {
		// UNCOMMENT & ADD DEFAULT LANG AS PARAM ex: 'EN'
		// dispatch(get_translate('EN'))
		dispatch(toggle_loader(true));
		return axios
			.get("oauth/check_login/")
			.then((resp) => {
				return dispatch(get_region()).then((region) => {
					const regions = region.data.data;
					const obj = Object.keys(regions).reduce((acc, curr) => {
						return [
							...acc,
							...regions[curr].cities.map((city) => ({
								...city,
								region_id: regions[curr].id,
							})),
						];
					}, []);
					const current = obj.find((o) => o.slug === cityName) || {};
					const cityId = current?.id;
					const regionId = current?.region_id;
					if (cityId) {
						return dispatch(
							validate_id(resp.data.user.account_id, 0, cityId)
						).then((childResp) => {
							dispatch(
								put_data("login", {
									...resp.data.user,
									...childResp.data,
									...{
										city_id: cityId,
										region_id: regionId,
										city_name: cityName,
									},
								})
							);
							return childResp;
						});
					}
				});
			})
			.catch((err) => {
				if (err?.response?.data?.msg === "event_not_open") {
					dispatch(put_data("popup_open", true));
					dispatch(put_data("popup_type", "errorGeneral"));
					dispatch(put_data("popup_data", "Pendaftaran belum dibuka"));
				}
			})
			.then(() => {
				dispatch(toggle_loader(false));
			});
	};
};

/* idx = 0 only for captain since the api check_login not providing level, and rank */
export const validate_id = (acc_id, idx, city_id) => {
	return (dispatch) => {
		dispatch(toggle_loader(true));

		let formdata = new FormData();
		formdata.append("account_id", parseInt(acc_id));
		if (city_id) formdata.append("city", city_id);

		return axios
			.post("api/validate/", formdata)
			.then((resp) => {
				dispatch(toggle_loader(false));
				if (idx === 0) return resp;
				document.querySelector(`input[name='nickname_${idx}']`).value =
					resp.data.nickname;
				document.querySelector(`input[name='level_${idx}']`).value =
					resp.data.level;
				document.querySelector(`input[name='rank_${idx}']`).value =
					resp.data.rank_name;
				return resp;
			})
			.catch((err) => {
				dispatch(put_data("popup_open", true));
				dispatch(put_data("popup_type", "error"));
				dispatch(put_data("popup_data", err_msg(err)));
				dispatch(toggle_loader(false));
				return err;
			});
	};
};

export const submit_form = (data) => {
	return (dispatch, getState) => {
		axios
			.post("api/registration/", data)
			.then((resp) => {
				const cityName = getState().main.login.city_name;
				dispatch(check_login(cityName));
			})
			.catch((err) => {
				const resp = err?.response?.data?.message;
				let field;
				let err_msg = "Opss, mohon coba kembali!";

				if (typeof resp === "object") {
					for (const key in resp) {
						field = key;
						// handle unknown fields error
						if (resp[key][0] === "unknown field") {
							err_msg = `Data ${field_mapper(
								"unknown_" + key.substring(key.length - 1, key.length)
							)} Belum Lengkap`;
						} else {
							err_msg = `${field_mapper(key)} ${resp[key][0]}`;
						}
					}
				} else {
					err_msg = resp;
				}

				dispatch(put_data("popup_open", true));
				dispatch(put_data("popup_type", "error"));
				dispatch(put_data("popup_data", err_msg));

				const el = document.querySelector(`label[for="${field}"]`);
				el.scrollIntoView({
					block: "start",
				});
			});
	};
};

const field_mapper = (field) => {
	const list = {
		general: "",
		account_id: "Nomor ID",
		team_name: "Nama Tim",
		guild_name: "Nama Guild",
		guild_id: "Guild ID",
		discord_id: "Discord ID",
		discord_username: "Discord Username",
		email: "Email",
		no_telp: "No Telephone",
		account_id_1: "Nomor ID Kapten",
		account_id_2: "Nomor ID Anggota 1",
		account_id_3: "Nomor ID Anggota 2",
		account_id_4: "Nomor ID Anggota 3",
		account_id_5: "Nomor ID Anggota 4",
		account_id_6: "Nomor ID Anggota Cadangan 1",
		account_id_7: "Nomor ID Anggota Cadangan 2",
		domisili_1: "Domisili Kapten",
		domisili_2: "Domisili Anggota 1",
		domisili_3: "Domisili Anggota 2",
		domisili_4: "Domisili Anggota 3",
		domisili_5: "Domisili Anggota 4",
		domisili_6: "Domisili Anggota Cadangan 1",
		domisili_7: "Domisili Anggota Cadangan 2",
		nama_lengkap_1: "Nama Lengkap Kapten",
		nama_lengkap_2: "Nama Lengkap Anggota 1",
		nama_lengkap_3: "Nama Lengkap Anggota 2",
		nama_lengkap_4: "Nama Lengkap Anggota 3",
		nama_lengkap_5: "Nama Lengkap Anggota 4",
		nama_lengkap_6: "Nama Lengkap Anggota Cadangan 1",
		nama_lengkap_7: "Nama Lengkap Anggota Cadangan 2",
		no_id_1: "Nomor Identitas Kapten",
		no_id_2: "Nomor Identitas Anggota 1",
		no_id_3: "Nomor Identitas Anggota 2",
		no_id_4: "Nomor Identitas Anggota 3",
		no_id_5: "Nomor Identitas Anggota 4",
		no_id_6: "Nomor Identitas Anggota Cadangan 1",
		no_id_7: "Nomor Identitas Anggota Cadangan 2",
		unknown_1: "Kapten",
		unknown_2: "Anggota 1",
		unknown_3: "Anggota 2",
		unknown_4: "Anggota 3",
		unknown_5: "Anggota 4",
		unknown_6: "Anggota Cadangan 1",
		unknown_7: "Anggota Cadangan 2",
	};

	return list[field];
};

const api_region = axios.create({
	baseURL: process.env.REACT_APP_API_ENDPOINT,
});

export const get_region = () => {
	return (dispatch) => {
		return api_region
			.get("/api/regions/")
			.then((response) => dispatch(put_data("regions", response.data)));
	};
};

export const get_json = (series) => {
	return (dispatch) => {
		const main_localize = cdn.get(`${series}.json?${Math.random() * 10000}`);
		const common_localize = common.get(
			`ID.json?${Math.floor(Math.random() * 10000)}`
		);
		const txt_localize = cdn.get(`common.json?${Math.random() * 10000}`);

		axios
			.all([txt_localize, main_localize, common_localize])
			.then((resp) => {
				const transform = resp.reduce((acc, curr) => {
					return { ...omitNull(acc), ...omitNull(curr.data) };
				}, {});

				console.table(transform);

				dispatch(put_data("json", transform));
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

const omitNull = (obj) => {
	Object.keys(obj)
		.filter((k) => obj[k] === null)
		.forEach((k) => delete obj[k]);
	return obj;
};
