import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { put_data } from "redux/actions/main";

import Error from "./Error";
import ErrorGeneral from "./ErrorGeneral";

const ModalCont = () => {
	const dispatch = useDispatch();
	const { popup_open, popup_type, popup_data } = useSelector(
		(state) => state?.main
	);
	console.log(popup_data);

	const selectModal = () => {
		switch (popup_type) {
			case "errorGeneral":
				return <ErrorGeneral data={popup_data} />;
			case "error":
				return <Error data={popup_data} />;
			default:
				return <div />;
		}
	};

	return (
		<Modal
			open={popup_open}
			classNames={{ modal: `modal-base ${popup_type}` }}
			onClose={() => dispatch(put_data("popup_open", false))}
			focusTrapped={false}
			center
		>
			{selectModal()}
		</Modal>
	);
};

export default ModalCont;
