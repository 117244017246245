import React from "react";
import { iframe } from "./style.module.css";

class Page404 extends React.Component {
	render() {
		return (
			<iframe
				className={iframe}
				title="page404"
				src="https://oops.garena.com/404/"
				frameBorder="0"
			></iframe>
		);
	}
}
export default Page404;
