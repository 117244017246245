import React from "react";
import { useSelector } from "react-redux";

const Header = () => {
	const { json } = useSelector((state) => state?.main);

	return (
		<div id="header" className="logo">
			<img src={json?.logo_image} alt="" />
		</div>
	);
};

export default Header;
